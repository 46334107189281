import request from "@/utils/request";
import E from "wangeditor";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx'

const {ElMessage} = require("element-plus");

export default {

    name: "BuildingInfo",
    components: {
    },

    data() {
        return {

            loading: true,
            disabled: false,
            judge: false,
            dialogVisible: false,
            detailDialog: false,
            search: "",
            currentPage: 1,
            pageSize: 10,
            total: 0,
            tableData: [],
            detail: {},
            form: {
                id: "",
                deliverytime:"",
                customer: "",
                writers: "",
                creationtime: "",
                customerID: "",
                finish: "",
                audit: "",
                remark: "",
                collection: "",
                payment: "",
                serve: "",
                demand: "",
                manner: "",
                settlement: "",
                incentive: "",
                ordernumber: "",
            },


            rules: {
                // id: [
                //     {required: true, message: "请输入编号", trigger: "blur"},
                //     {
                //         pattern: /^(1|[1-9]\d?|100)$/,
                //         message: "范围：1-100",
                //         trigger: "blur",
                //     },
                // ],
                // dormBuildName: [
                //     {required: true, message: "请输入名称", trigger: "blur"},
                // ],
                // dormBuildDetail: [
                //     {required: true, message: "请输入备注", trigger: "blur"},
                // ],
            },
        };
    },
    created() {
        this.load();
        this.loading = true;
        setTimeout(() => {
            //设置延迟执行
            this.loading = false;
        }, 1000);
    },
    methods: {
        exportTable() {
            // 获取表格数据
            const tableData = this.tableData;

            // 创建一个新的工作簿
            const workbook = XLSX.utils.book_new();

            // 将数据转换为工作表
            const worksheet = XLSX.utils.json_to_sheet(tableData);

            // 将工作表添加到工作簿
            XLSX.utils.book_append_sheet(workbook, worksheet, '订单信息');

            // 生成 Excel 文件
            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            // 保存文件
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, '订单信息.xlsx');
        },



        async load() {
            request.get("/building/find", {
                params: {
                    pageNum: this.currentPage,
                    pageSize: this.pageSize,
                    search: this.search,
                },
            }).then((res) => {
                console.log(res);
                this.tableData = res.data.records;
                this.total = res.data.total;
                this.loading = false;
            });
        },
        closeDetailDialog() {
            this.detailDialog = false;
        },
        reset() {
            this.search = ''
            request.get("/building/find", {
                params: {
                    pageNum: 1,
                    pageSize: this.pageSize,
                    search: this.search,
                },
            }).then((res) => {
                console.log(res);
                this.tableData = res.data.records;
                this.total = res.data.total;
                this.loading = false;
            });
        },
        filterTag(value, row) {
            return row.finish === value;
        },
        filterTag1(value, row) {
            return row.audit === value;
        },
        filterTag2(value, row) {
            return row.settlement === value;
        },
        add() {
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                this.disabled = false;
                this.form = {};
                this.form.finish = '未完成'; // 设置默认值为"未完成"
                this.form.audit = '未审核'; // 设置默认值为"未审核"
                this.form.settlement = '未结算'; // 设置默认值为"未结算"
                const localTime = new Date();
                const chinaTime = new Date(localTime.getTime() + (8 * 60 * 60 * 1000)); // 增加8小时的毫秒数
                const formattedTime = chinaTime.toISOString().slice(0, 19).replace('T', ' ');

                this.form.creationtime = formattedTime; // 设置创建时间为格式化后的值


                this.judge = false;
                this.judge = false;
            });
        },
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.judge === false) {
                        //新增
                        request.post("/building/add", this.form).then((res) => {
                            console.log(res);
                            if (res.code === "0") {
                                ElMessage({
                                    message: "新增成功",
                                    type: "success",
                                });
                                this.search = "";
                                this.load();
                                this.dialogVisible = false;
                            } else {
                                ElMessage({
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                        });
                    } else {
                        //修改
                        request.put("/building/update", this.form).then((res) => {
                            console.log(res);
                            if (res.code === "0") {
                                ElMessage({
                                    message: "修改成功",
                                    type: "success",
                                });
                                this.search = "";
                                this.load();
                                this.dialogVisible = false;
                            } else {
                                ElMessage({
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                        });
                    }
                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.dialogVisible = false;
        },
        showDetail(row) {
            this.detailDialog = true;
            this.$nextTick(() => {
                this.detail = row;
            });
        },
        handleEdit(row) {
            //修改
            this.judge = true;
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                // 生拷贝
                this.form = JSON.parse(JSON.stringify(row));
                this.disabled = true;
            });
        },
        handleDelete(id) {
            console.log();
            request.delete("/building/delete/" + id).then((res) => {
                if (res.code === "0") {
                    ElMessage({
                        message: "删除成功",
                        type: "success",
                    });
                    this.search = "";
                    this.load();
                } else {
                    ElMessage({
                        message: res.msg,
                        type: "error",
                    });
                }
            });
        },
        handleSizeChange(pageSize) {
            //改变每页个数
            this.pageSize = pageSize;
            this.load();
        },
        handleCurrentChange(pageNum) {
            //改变页码
            this.currentPage = pageNum;
            this.load();
        },
    },
};